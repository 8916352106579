export class FilterObject {
  constructor() {}

  search: string;
  isfurnished: string = "";
  minprice: number = 0;
  maxprice: number = 2000;
  roomsCount: string = "";
  status: string = "";
  gender: string = "";
  city: string = "";
  neighborhoods: string[] = [];
  accommodations: string[] = [];
  facilities: string[] = [];
  sort: string;
  sortorder: string;
  wholeApartment: string = "";
  limit: number = 12;
  skip: number = 0;
  // moveInDate: Date = new Date();
}
