import {Injectable} from "@angular/core";

@Injectable()
export class Constants {
    constructor() {
    }

    getMinimumStayList(): any {
        return [
            {id: 1, value: "No minimum stay", label: "No minimum stay"},
            {id: 2, value: "1 month", label: "1 month"},
            {id: 3, value: "2 months", label: "2 months"},
            {id: 4, value: "3 months", label: "3 months"},
            {id: 5, value: "4 months", label: "4 months"},
            {id: 6, value: "5 months", label: "5 months"},
            {id: 7, value: "6 months", label: "6 months"},
            {id: 8, value: "7 months", label: "7 months"},
            {id: 9, value: "8 months", label: "8 months"},
            {id: 10, value: "9 months", label: "9 months"},
            {id: 11, value: "10 months", label: "10 months"},
            {id: 12, value: "11 months", label: "11 months"},
            {id: 13, value: "1 year", label: "1 year"},
            {id: 14, value: "1+ year", label: "1+ year"},
        ];
    }

    getBedTypeList(): any {
        return [
            {id: 1, value: "Single Bed", label: "Single Bed"},
            {id: 2, value: "Double Bed", label: "Double Bed"},
            {id: 3, value: "Queen Bed", label: "Queen Bed"},
            {id: 4, value: "King Bed", label: "King Bed"},
            {id: 5, value: "Bunk Bed", label: "Bunk Bed"},
            {id: 6, value: "Sofa Bed", label: "Sofa Bed"},
            {id: 7, value: "Air Mattress", label: "Air Mattress"},
        ];
    }

    getPurposesOfStay(): any {
        return [
            {id: 0, value: null, label: "Please select"},
            {id: 1, value: "Studies", label: "Study"},
            {id: 2, value: "Internship", label: "Internship"},
            {id: 3, value: "Job Employment", label: "Job Employment"},
            {id: 4, value: "Tourism", label: "Tourism"},
            {id: 5, value: "Business Trip", label: "Business Trip"},
        ];
    }

    getApartmentSize(): any {
        return [
            {id: 1, description: "1 ½", label: "1 ½"},
            {id: 2, description: "2 ½", label: "2 ½"},
            {id: 3, description: "3 ½", label: "3 ½"},
            {id: 4, description: "4 ½", label: "4 ½ - 2 bedrooms"},
            {id: 5, description: "5 ½", label: "5 ½ - 3 bedrooms"},
            {id: 6, description: "6 ½", label: "6 ½ - 4 bedrooms"},
            {id: 7, description: "7 ½", label: "7 ½ or more - 5 bedrooms or more"}
        ];
    }

    getAccomodations(): any {
        let accomodations = [
            {id: 1, name: "High Speed WiFi", isSelected: false},
            {id: 2, name: "Air Conditioning", isSelected: false},
            {id: 3, name: "Parking", isSelected: false},
            {id: 4, name: "Central Heating", isSelected: false},
            {id: 5, name: "Laundry Room", isSelected: false},
            {id: 6, name: "Washer", isSelected: false},
            {id: 7, name: "Gym", isSelected: false},
            {id: 8, name: "Alarm System", isSelected: false },
            {id: 13, name: "TV", isSelected: false }
        ];

        return accomodations;
    }

    getAccomodationsCriterias(): any {
        let accomodations = [
            {id: 9, name: "Pet Friendly", isSelected: false},
            {id: 10, name: "Couple Friendly", isSelected: false},
            {id: 11, name: "Family Friendly", isSelected: false},
            {id: 12, name: "Smoking", isSelected: false}
        ];

        return accomodations;
    }

    getAccommodationsFilter(): any {
        let accomodations = [
            {id: 10, name: "Smoking ok"},
            {id: 9, name: "Pet ok"},
            {id: 11, name: "Couple ok"},
        ];

        return accomodations;
    }

    getFacilities(): any {
        let facilities = [
            {id: 1, name: "Bedding", isSelected: false},
            {id: 2, name: "Closet", isSelected: false},
            {id: 3, name: "Private bathroom", isSelected: false},
            {id: 4, name: "Desk", isSelected: false},
            {id: 5, name: "Windows", isSelected: false},
            {id: 6, name: "Dresser", isSelected: false},
            {id: 7, name: "Bedside lamp", isSelected: false},
            {id: 8, name: "Plants", isSelected: false},
            {id: 9, name: "Balcony", isSelected: false},
            {id: 10, name: "TV", isSelected: false }
        ];

        return facilities;
    }

    getFacilitiesFilter(): any {
        let facilities = [
            {id: 5, name: "Window"},
            {id: 4, name: "Desk"},
            {id: 3, name: "Private bathroom"},
        ];

        return facilities;
    }

    getPropertyAccessList(): any {
        return [
            {value: "host-wait-guest", name: "I will wait for the Guest in the property"},
            {value: "host-give-keys", name: "I will give the keys to the Guest outside the property"},
            {value: "someone-give-keys", name: "Someone else will be in charge of delivering the keys"},
            {value: "self-checkin", name: "Guest can self-check-in"},
            {value: "agent", name: "I prefer to give the keys to a Hivenue agent (only for Montreal)"}
        ];
    }

    getHostWaitGuestTimeList(): any {
        return [
            {value: "morning", name: "Morning - Between 8 a.m to 12 p.m"},
            {value: "afternoon", name: "Afternoon - Between 12 p.m to 6 p.m"},
            {value: "evening", name: "Evening - After 6 p.m"},
        ]
    }

    getAboutMePersonalityList() {
        return [
            {id: 1, name: 'Active'},
            {id: 2, name: 'Calm'},
            {id: 3, name: 'Caring'},
            {id: 4, name: 'Cheerful'},
            {id: 5, name: 'Cooperative'},
            {id: 6, name: 'Creative'},
            {id: 7, name: 'Determined'},
            {id: 8, name: 'Easygoing'},
            {id: 9, name: 'Empathetic'},
            {id: 10, name: 'Enthusiastic'},
            {id: 11, name: 'Flexible'},
            {id: 12, name: 'Friendly'},
            {id: 13, name: 'Fun'},
            {id: 14, name: 'Honest'},
            {id: 15, name: 'Joker'},
            {id: 16, name: 'Optimistic'},
            {id: 17, name: 'Organized'},
            {id: 18, name: 'Passionate'},
            {id: 19, name: 'Practical'},
            {id: 20, name: 'Proactive'},
            {id: 21, name: 'Rational'},
            {id: 22, name: 'Relaxed'},
            {id: 23, name: 'Sociable'},
            {id: 24, name: 'Tolerant'},
        ];
    }

    getAboutMeLifestyleList() {
        return [
            {id: 1, name: 'Adventurer'},
            {id: 2, name: 'Art enthusiast'},
            {id: 3, name: 'Athlete'},
            {id: 4, name: 'Book lover'},
            {id: 5, name: 'Chef'},
            {id: 6, name: 'Cinema goer'},
            {id: 7, name: 'Culture enthusiast'},
            {id: 8, name: 'Dancer'},
            {id: 9, name: 'Early bird'},
            {id: 10, name: 'Entrepreneur'},
            {id: 11, name: 'Family person'},
            {id: 12, name: 'Fashionista'},
            {id: 13, name: 'Foodie'},
            {id: 14, name: 'Gamer'},
            {id: 15, name: 'Health enthusiast'},
            {id: 16, name: 'Homebird'},
            {id: 17, name: 'Avid music lover'},
            {id: 18, name: 'Night owl'},
            {id: 19, name: 'Partier'},
            {id: 20, name: 'Pet lover'},
            {id: 21, name: 'Photographer'},
            {id: 22, name: 'Shopper'},
            {id: 23, name: 'Singer'},
            {id: 24, name: 'Tech lover'},
            {id: 25, name: 'Theatre fan'},
            {id: 26, name: 'Traveller'},
            {id: 27, name: 'Vegan'},
            {id: 28, name: 'Vegetarian'},
            {id: 29, name: 'Walker'},
            {id: 30, name: 'Workaholic'},
        ];
    }

    getAboutMeInterestsList() {
        return [
            {id: 1, name: 'Art'},
            {id: 2, name: 'Beach'},
            {id: 3, name: 'Board games'},
            {id: 4, name: 'Coffee'},
            {id: 5, name: 'Comics'},
            {id: 6, name: 'Concerts'},
            {id: 7, name: 'Cooking'},
            {id: 8, name: 'Crafts/DIY'},
            {id: 9, name: 'Crypto'},
            {id: 10, name: 'Dancing'},
            {id: 11, name: 'Exploring the city'},
            {id: 12, name: 'Fitness'},
            {id: 13, name: 'Hiking'},
            {id: 14, name: 'Hosting people'},
            {id: 15, name: 'Ice cream'},
            {id: 16, name: 'Movies'},
            {id: 17, name: 'Music'},
            {id: 18, name: 'Nightlife'},
            {id: 19, name: 'Outdoors'},
            {id: 20, name: 'Politics'},
            {id: 21, name: 'Reading'},
            {id: 22, name: 'Restaurants'},
            {id: 23, name: 'Running'},
            {id: 24, name: 'Shopping'},
            {id: 25, name: 'Skiing/Snowboarding'},
            {id: 26, name: 'Sports'},
            {id: 27, name: 'Television'},
            {id: 28, name: 'Theater'},
            {id: 29, name: 'Traveling'},
            {id: 30, name: 'Video games'},
            {id: 31, name: 'Volunteering'},
            {id: 32, name: 'Watching sports'},
            {id: 33, name: 'Yoga'},
        ];
    }

    getAboutMeMusicList() {
        return [
            {id: 1, name: 'Alternative'},
            {id: 2, name: 'Asian pop'},
            {id: 3, name: 'Blues'},
            {id: 4, name: 'Classical'},
            {id: 5, name: 'CountryMusic'},
            {id: 6, name: 'Dance'},
            {id: 7, name: 'Electronic'},
            {id: 8, name: 'Folk'},
            {id: 9, name: 'Funk'},
            {id: 10, name: 'Gospel'},
            {id: 11, name: 'Hip-Hop'},
            {id: 12, name: 'Indie'},
            {id: 13, name: 'Instrumental'},
            {id: 14, name: 'Jazz'},
            {id: 15, name: 'Latin'},
            {id: 16, name: 'Lounge'},
            {id: 17, name: 'Metal'},
            {id: 18, name: 'New age'},
            {id: 19, name: 'Opera'},
            {id: 20, name: 'Pop'},
            {id: 21, name: 'Punk'},
            {id: 22, name: 'R&B'},
            {id: 23, name: 'Reggae'},
            {id: 24, name: 'Rock'},
            {id: 25, name: 'Ska'},
            {id: 26, name: 'Soul'},
        ];
    }

    getAboutMeSportsList() {
        return [
            {id: 1, name: 'American football'},
            {id: 2, name: 'Athletics'},
            {id: 3, name: 'Badminton'},
            {id: 4, name: 'Baseball'},
            {id: 5, name: 'Basketball'},
            {id: 6, name: 'Bodyboarding'},
            {id: 7, name: 'Bowls'},
            {id: 8, name: 'Boxing'},
            {id: 9, name: 'Canoeing'},
            {id: 10, name: 'Climbing'},
            {id: 11, name: 'Cricket'},
            {id: 12, name: 'Diving'},
            {id: 13, name: 'Football (Soccer)'},
            {id: 14, name: 'Golf'},
            {id: 15, name: 'Gym'},
            {id: 16, name: 'Hiking'},
            {id: 17, name: 'Hockey'},
            {id: 18, name: 'Horse riding'},
            {id: 19, name: 'Go-karting'},
            {id: 20, name: 'Martial arts'},
            {id: 21, name: 'Motorcycling'},
            {id: 22, name: 'Paddle'},
            {id: 23, name: 'Pole dancing'},
            {id: 24, name: 'Rowing'},
            {id: 25, name: 'Rugby'},
            {id: 26, name: 'Running'},
            {id: 27, name: 'Skateboarding'},
            {id: 28, name: 'Skating'},
            {id: 29, name: 'Skiing'},
            {id: 30, name: 'Snowboarding'},
            {id: 31, name: 'Squash'},
            {id: 32, name: 'Surfing'},
            {id: 33, name: 'Swimming'},
            {id: 34, name: 'Table tennis'},
            {id: 35, name: 'Tennis'},
            {id: 36, name: 'Volleyball'},
            {id: 37, name: 'Water polo'},
        ];
    }

    getAboutMeMoviesGenreList() {
        return [
            {id: 1, name: 'Action'},
            {id: 2, name: 'Adventure'},
            {id: 3, name: 'Animation'},
            {id: 4, name: 'Anime'},
            {id: 5, name: 'Comedy'},
            {id: 6, name: 'Crime'},
            {id: 7, name: 'Documentary'},
            {id: 8, name: 'Drama'},
            {id: 9, name: 'Fantasy'},
            {id: 10, name: 'Historical'},
            {id: 11, name: 'Horror'},
            {id: 12, name: 'Mystery'},
            {id: 13, name: 'Political'},
            {id: 14, name: 'Romance'},
            {id: 15, name: 'Saga'},
            {id: 16, name: 'Satire'},
            {id: 17, name: 'Sci-fi'},
            {id: 18, name: 'Thriller'},
            {id: 19, name: 'Western'},
        ];
    }
}

/* Photo uploading parameters when creating a listing */
export const MIN_PHOTOS: number = 3;
export const MAX_PHOTOS: number = 20;

/* Rental fee calculation parameters */
export const TAX_PER_DAY: number = 1;

export const SERVICE_FEE_RATE: number = 0.12;                       // Rate of service fee when the stay is not long term
export const MAX_SHORT_STAY_DAYS = 62;                              // Days of long term rental

/* Min & Max value for age group in create form */
export const MIN_AGE: number = 18;
export const MAX_AGE: number = 40;
/* Minimum words in the message on the application summary page */
export const MIN_MESSAGE_WORDS: number = 15;
/* Window for filter by move-in date */
export const FILTER_MOVE_IN_DATE_WINDOW: number = 14;

export const MINIMUM_TAX: number = 42;
export const MINIMUM_RENT_FOR_PERCENTAGE_TAX: number = 875;
export const PERCENT_TAX_RATE = 0.048; // 4.8%
