import {
    Component,
    ElementRef,
    Renderer2,
    Input,
    Output,
    EventEmitter
} from '@angular/core';
import {Utils} from '../../../utils/utils';

import {ConditionsObject} from '../../../models/conditionsobject';

import {
    GeneralController, UserController
} from '../../../controllers/controllers.module';

import * as _ from 'lodash'

@Component({
    selector: 'app-confirmation-modal',
    templateUrl: './confirmation-modal.component.html',
    styleUrls: ['./confirmation-modal.component.css'],
    providers: [Utils]
})

export class ConfirmationModalComponent {
    @Output() emitter = new EventEmitter<String>();
    @Input() status: string
    @Input() firstName: string
    @Input() lastName: string
    @Input() stage: string
    yesButtonContent: string
    noButtonContent: string
    topMessageContent: string
    topHeadingContent: string
    textBoxEnabled = false
    noButtonRequired = true
    yesButtonDisabled = true

    conditions: ConditionsObject = this.genCtrl.getNewConditionsObject();
    modalEl = null
    message: string

    constructor(
        private utils: Utils,
        private el: ElementRef,
        private renderer: Renderer2,
        private genCtrl: GeneralController
    ) {
    }

    onCloseModal() {
        if (this.applicationSent()) {
            this.emitter.emit('true');
        }
        this.renderer.setStyle(this.modalEl, 'display', 'none');
        this.renderer.removeClass(this.modalEl, 'opened');
    }

    ngOnInit() {
        this.utils.executeBrowserOnly(() => {
            this.conditions.isBrowser = true;
        });
    }

    ngOnChanges() {
        this.setButtonsText()
    }

    setButtonsText() {
        if (this.adminApproving()) {
            this.topHeadingContent = `Document Verification`
            this.topMessageContent = `Have you verified guest documents ?`
            this.noButtonContent = `No`
            this.yesButtonContent = `Yes`
            this.noButtonRequired = true
            this.textBoxEnabled = false
        } else if (this.adminDeclining()) {
            this.topHeadingContent = `Confirm`
            this.topMessageContent = `Are you sure you want to decline ?`
            this.noButtonContent = `Cancel`
            this.yesButtonContent = `Decline`
            this.noButtonRequired = true
            this.textBoxEnabled = false
        } else if (this.adminClosing() || this.hostClosing()) {
            this.topHeadingContent = `Close Application`
            this.topMessageContent = `Are you sure you want to close this window ?`
            this.noButtonContent = `No`
            this.yesButtonContent = `Yes`
            this.noButtonRequired = true
            this.textBoxEnabled = false
        } else if (this.hostApproving()) {
            this.topHeadingContent = `Message`
            this.topMessageContent = `Write a kind message to`
            this.noButtonContent = `Cancel`
            this.yesButtonContent = `Send Message`
            this.textBoxEnabled = true
            this.noButtonRequired = true
        } else if (this.hostDeclining()) {
            this.topHeadingContent = `Decline Application`
            this.topMessageContent = `Are you sure you want to decline this guest ?`
            this.noButtonContent = `Cancel`
            this.yesButtonContent = `Yes, decline`
            this.noButtonRequired = true
            this.textBoxEnabled = false
        } else if (this.applicationSummaryCancellation()) {
            this.topHeadingContent = 'Cancel Application'
            this.topMessageContent = 'Are yo sure you want to cancel this application ?'
            this.noButtonContent = 'No, continue'
            this.yesButtonContent = 'Yes, cancel'
            this.message = 'true'
            this.noButtonRequired = true
            this.textBoxEnabled = false
        } else if (this.paymentCancellation()) {
            this.topHeadingContent = 'Cancel'
            this.topMessageContent = 'Are yo sure you want to cancel payment ?'
            this.noButtonContent = 'No'
            this.yesButtonContent = 'Yes'
            this.message = 'true'
            this.noButtonRequired = true
            this.textBoxEnabled = false
        } else if (this.paymentSuccess()) {
            this.topHeadingContent = 'Request submitted'
            this.topMessageContent = 'Your request has been sent to the host successfully. We will infrom you once the host approves your request.'
            this.noButtonContent = 'No'
            this.yesButtonContent = 'OK'
            this.message = 'true'
            this.noButtonRequired = false
            this.textBoxEnabled = false
        } else if (this.applicationSent()) {
            this.topHeadingContent = 'Application Sent'
            this.topMessageContent = 'Your booking request is sent, please expect a response via email from Hivenue.'
            this.noButtonContent = 'No'
            this.yesButtonContent = 'OK'
            this.message = 'true'
            this.noButtonRequired = false
            this.textBoxEnabled = false
        }
    }

    setMessage(event) {
        this.message = event
        if (!_.isEmpty(this.message)) {
            this.yesButtonDisabled = false
        }
    }

    sayYes() {
        this.emitter.emit(this.message)
        this.onCloseModal()
    }

    ngAfterViewInit() {
        if (this.el.nativeElement.querySelector) {
            this.modalEl = this.el.nativeElement.querySelector('div.modal');
        }
    }

    adminApproving() {
        return this.status === 'approve' && this.stage === 'ADMIN'
    }

    adminDeclining() {
        return this.status === 'decline' && this.stage === 'ADMIN'
    }

    adminClosing() {
        return this.status === 'close' && this.stage === 'ADMIN'
    }

    hostApproving() {
        return this.status === 'approve' && this.stage === 'HOST'
    }

    hostDeclining() {
        return this.status === 'decline' && this.stage === 'HOST'
    }

    hostClosing() {
        return this.status === 'close' && this.stage === 'HOST'
    }

    applicationSummaryCancellation() {
        return this.status === 'cancel' && this.stage === 'applicationSummary'
    }

    paymentCancellation() {
        return this.status === 'cancel' && this.stage === 'payment'
    }

    paymentSuccess() {
        return this.status === 'success' && this.stage === 'payment'
    }

    approvalSuccess() {
        return this.status === 'rentRequestApproval' && this.stage === 'none'
    }

    applicationSent() {
         return this.status === 'sent' && this.stage === 'applicationSummary'
    }
}
